import Favicon from "react-favicon";

import "./css/landing-page.scss";

import "./css/page-one.scss";
import "./css/page-two.scss";
import "./css/page-three.scss";
import "./css/page-four.scss";

function App() {
    const logo =
        "https://uselpate.sirv.com/Images/LOSS%20VEGASLogoTransparent.png";

    //Page 1 Characters.
    const sloan = "https://uselpate.sirv.com/Images/SloanFlying.png";

    //Page 2.
    const lambo = "https://uselpate.sirv.com/Images/Lambo2-01.png";
    const fridge = "https://uselpate.sirv.com/Images/LV_episodes_color.png";

    //Page 3.
    const card = "https://uselpate.sirv.com/Images/Cards-01.png";

    //Page 4.
    const car = "https://uselpate.sirv.com/Images/Car.png";

    //Social Media Icons.
    const medium = "https://uselpate.sirv.com/Images/medium.png";
    const twitter = "https://uselpate.sirv.com/Images/twitter.png";
    const telegram = "https://uselpate.sirv.com/Images/telegramSVG-01.svg";

    return (
        <div className="landing-page">
            <div className="page1-container">
                {/*logo*/}
                <img className="logo-main" src={logo} />

                {/*page 1 text boxes*/}
                <div className="page1-text-boxes">
                    <h1 className="description">
                        <p>
                            {" "}
                            Blockchain Community Animated Show.
                            <br></br>
                            Users earn money by writing, voting, creating and
                            sharing content.
                        </p>
                        <h2 className="sub-description">
                            Make Influencers Cool Again
                        </h2>
                    </h1>

                    {/*character images*/}
                    <img src={sloan} className="sloan" />

                    <div className="bullet-container">
                        <div className="bullet-box">
                            <div className="page1-video-container">
                                <video
                                    className="page1-video"
                                    autoPlay
                                    loop
                                    muted
                                    poster="https://uselpate.sirv.com/Images/LossVegasOriginalBigNobNFTWHITE.webm?thumbnail=2400"
                                >
                                    <source
                                        src="https://uselpate.sirv.com/Images/LossVegasOriginalBigNobNFTWHITE.webm"
                                        type="video/mp4"
                                    />
                                </video>
                            </div>

                            <div className="airdrop-desc">
                                {" "}
                                Claim our FREE Loss Vegas 1st commemorative NFT{" "}
                                <br></br>
                                <br></br> Limited supply of 250. First come,
                                first serve.
                            </div>

                            <table className="bullet-table">
                                <tr>
                                    <td>
                                        <a
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="twitter-follow-button"
                                            href="https://twitter.com/intent/follow?original_referer=https%3A%2F%2Flossvegas.io&ref_src=twsrc%5Etfw&region=follow_link&screen_name=LossVegasShow&tw_p=followbutton"
                                        >
                                            1. Follow us on Twitter.
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        2.{" "}
                                        <a
                                            href="https://docs.google.com/forms/d/e/1FAIpQLScyuIVBNJIvCso5bOeW_3zMoc9p7f4cKWxAE8_8krh-fc9R_g/viewform?usp=sf_link"
                                            target="_blank"
                                        >
                                            Sign up using our quick Air Drop
                                            form.{" "}
                                        </a>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div className="page2-container">
                <div className="page2-text-boxes">
                    <div className="what-is-box">
                        <img src={lambo} className="lambo" />

                        <div className={"what-is-text"}>
                            <h1 className="what-is-title">
                                What is Loss Vegas?
                            </h1>
                            <h2 className="what-is-desc">
                                Loss Vegas is an animated series created by the
                                community where degeneracy is handsomely
                                rewarded.<br></br>
                                <br></br> YOU determine what the story is.
                                <br></br> YOU can make money creating it too.
                                <br></br>
                                <br></br> Sarcastic assholes are
                                enthusiastically welcomed.
                            </h2>

                            <h1 className="what-is-title">
                                {" "}
                                How are we going to do it?
                            </h1>
                            <h2 className="what-is-desc">
                                That’s the secret that comes with a never before
                                done production, make sure to sign up and we
                                will keep you updated along the way.
                            </h2>
                        </div>
                    </div>
                </div>

                <img src={fridge} className="fridge" />
            </div>

            <div className="page3-container">
                <div className="cards">
                    <h1 className={"card-title"}>Sneak Peak</h1>
                    {/*<h2 className={"card-desc"}>*/}
                    {/*</h2>*/}
                    <img src={card} className="cards-example" />
                </div>
            </div>

            <div className="page4-container">
                <div className="page4-text-boxes">
                    <div className="connect-with-us">
                        <h1 className={"connect-title"}>Connect With Us</h1>

                        <a
                            href="https://twitter.com/LossVegasShow"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img src={twitter} className={"twitter"} />
                        </a>

                        <a
                            href="https://medium.com/@lossvegasshow"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img src={medium} className={"medium"} />
                        </a>

                        <a
                            href="https://t.me/lossvegas"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img src={telegram} className={"telegram"} />
                        </a>
                    </div>

                    <img src={car} className="car"></img>
                </div>
            </div>
        </div>
    );
}

export default App;
